import ContactPerson from "./ContactPerson";
import styled from "styled-components";
import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 1100px;
  padding: 20px 20px 25px;
  position: relative;
  margin: 5% auto;
`; 

const ContactPersonWrapper = ({ title, subtitle, image, name, position, email, phone }) => {
    return (
        <Wrapper>
            <ContactPerson 
                title={title}
                subtitle={subtitle}
                image={image}
                name={name}
                position={position}
                email={email}
                phone={phone}
            />
        </Wrapper>
    )
};

ContactPersonWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
};

export default ContactPersonWrapper;