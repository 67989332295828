import React, { useState, useRef, useEffect } from "react"
import { Link, useStaticQuery, graphql  } from "gatsby"
import styled from "styled-components"
import useScrollListener from "src/hooks/useScrollListener"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseBrandLogo from "src/components/Base/BaseBrandLogo"
import HeaderNavigation from "./HeaderNavigation"
import Banner from "src/components/Banner"

const Header = ({ variant, pathname }) => {
  const lastScrollPosition = useRef(0)
  const scrolledDown = useRef(false)
  const scrolledUp = useRef(false)
  const [hasBg, setHasBg] = useState(false)
  const [isHidden, setHidden] = useState(false)
  const delta = 30
  const hiddenBreakpoint = 100

  const { allStrapiBanner } = useStaticQuery(graphql`
    query {
      allStrapiBanner {
        nodes {
          banner_text
          banner_link_url
          show_banner
        }
      }
    }
  `);

  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    if (allStrapiBanner.nodes && allStrapiBanner.nodes.length > 0 && allStrapiBanner.nodes[0].show_banner) {
      setBanner(allStrapiBanner.nodes[0]);
      setIsBannerVisible(true);
    } else {
      setIsBannerVisible(false);
    }
  }, [allStrapiBanner.nodes]);

  function handleScroll() {
    const position = window.scrollY
    const bgBreakpoint = 150
    if (Math.abs(lastScrollPosition.current - position) > delta) {
      scrolledDown.current = position - delta > lastScrollPosition.current
      scrolledUp.current = !scrolledDown.current
      lastScrollPosition.current = position
    }
    setHidden(scrolledDown.current && position > hiddenBreakpoint)
    setHasBg(position > bgBreakpoint)
  }

  // todo: refactor
  function hideMenu() {
    const className = "-menu-visible"
    const element = document.querySelector(`.${className}`)
    if (!element) return
    element.classList.remove(`${className}`)
  }

  useScrollListener({
    callback: handleScroll,
  })

  return (
    <Wrapper
      variant={variant}
      isBannerVisible={isBannerVisible}
      className={`${hasBg ? "-bg" : ""} ${isHidden ? "-hidden" : "-visible"}`}
    >
      {isBannerVisible && (
        <Banner link={banner.banner_link_url} text={banner.banner_text}/>
      )}
      <Container wide>
      <Navigation bannerActive={isBannerVisible} />
      <Link id='aiLogo' to="/" onClick={hideMenu} title="Anima International">
        <StyledLogo variant={variant} />
      </Link>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  width: 100%;
  ${(props) => (props.isBannerVisible) ? 'height: 8.5em;' : ''}
  padding: ${(props) => (props.isBannerVisible && !props.isMenuExpanded) ? '0' : '20px'} 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  color: ${(p) => (p.variant === "light" ? "#fff" : "#000")};
  transition: 0.25s ${(p) => p.theme.easing.easeOutQuad};
  transition-property: visibility, opacity, padding, transform;

  &.-bg {
    padding: ${(props) => (props.isBannerVisible && !props.isMenuExpanded) ? '0' : '20px'} 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 1px 0 rgba(0, 11, 33, 0.1);
  }

  &.-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
  }

  ${media.tablet} {
    padding: ${(props) => (props.isBannerVisible && !props.isMenuExpanded) ? '0' : '40px'} 0;
  }

  @media (min-height: 750px) {
    &.-bg {
      padding: ${(props) => (props.isBannerVisible && !props.isMenuExpanded) ? '0' : '30px'} 0;
    }
  }
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  ${media.tablet} {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: 1s 1.4s ${(p) => p.theme.easing.easeOutExpo};
    transition-property: visibility, opacity, transform;

    .wf-poppinswf-n2-active.wf-poppinswf-n5-active & {
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
`

const StyledLogo = styled(BaseBrandLogo)`
  width: 100px;
  position: relative;
  z-index: 1;
  color: ${(p) => (p.variant === "light" ? "" : p.theme.color.primary)};
  transition: width 0.25s ${(p) => p.theme.easing.easeOutQuad};

  ${Wrapper}.-bg & {
    color: ${(p) => p.theme.color.primary};
  }

  ${media.tablet} {
    width: 120px;

    ${Wrapper}.-bg & {
      width: 100px;
    }
  }
`

const Navigation = styled(HeaderNavigation)`
  ${Wrapper}.-bg & {
    color: #000;
  }
`

export default Header
