import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import BaseIcon from "src/components/Base/BaseIcon";
import { media } from "src/styles/breakpoints";
import { TertiaryTitle } from "src/components/Typography"

const ContactPerson = ({ title, subtitle, image, name, position, email, phone }) => {
  return (
    <Contact>
        <ContactInner>
            <ContactTitle>{title}</ContactTitle>
            <ContactSubtitle>
                {subtitle}
            </ContactSubtitle>
            <ContactPersonWrapper>
                <ContactImage src={image} alt={`${name} photo`} />
                <ContactPersonDetails>
                    <ContactName>{name}</ContactName>
                    <ContactPosition>{position}</ContactPosition>
                    <ContactLink href={`mailto:${email}`}>
                    <ContactLinkIcon name="mail" source="feather" />
                    {email}
                    </ContactLink>
                    <br />
                    {phone && 
                        <ContactLink href={`tel:${phone}`}> 
                            <ContactLinkIcon name="phone" source="feather" />
                            {phone}
                        </ContactLink>
                    }
                </ContactPersonDetails>
            </ContactPersonWrapper>
        </ContactInner>
    </Contact>
  );
};

ContactPerson.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
};

const ContactPersonWrapper = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};

  ${media.tablet} {
    display: flex;
    align-items: center;
  }
`;

const ContactImage = styled.img`
  width: 100px;
  min-width: 100px;
  margin-right: 40px;
  margin-bottom: 30px;
  border-radius: 50%;

  ${media.tablet} {
    margin-bottom: 0;
  }

  ${media.desktopMedium} {
    width: 120px;
    min-width: 120px;
    margin-right: 50px;
  }
`;

const ContactPersonDetails = styled.div``;

const ContactName = styled.h4`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.07em;
  color: #000;
`;

const ContactPosition = styled.p`
  margin-bottom: 10px;
`;

const ContactLink = styled.a`
  display: inline-block;
  padding: 2px 0;
  font-size: 10px;

  ${media.tablet} {
    font-size: 11px;
  }

  ${media.desktopMedium} {
    font-size: inherit;
  }
`;

const ContactLinkIcon = styled(BaseIcon)`
  opacity: 0.7;
  width: 16px;
  height: 16px;
  margin-right: 15px;
  color: #8c9d9d;
`;

const ContactInner = styled.div`
  position: relative;
`

const ContactTitle = styled(TertiaryTitle)`
  margin-bottom: 20px;
`

const ContactSubtitle = styled.p`
  margin-bottom: 80px;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`


const Contact = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  ${media.tablet} {
    padding: 60px 60px 70px;
  }

  ${media.desktopMedium} {
    padding: 70px 70px 80px;
  }
`

export default ContactPerson;