import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media, breakpoints } from "src/styles/breakpoints"
import DropdownMenu from "src/components/Form/DropdownMenu"
import { useLocation } from '@reach/router';
import BaseIcon from "src/components/Base/BaseIcon"

const HeaderNavigation = ({ className, bannerActive }) => {
  const { allStrapiMenu } = useStaticQuery(
    graphql`
      query {
        allStrapiMenu(filter: { parent_menu_dropdown: { id: { eq: null } } }) {
          nodes {
            title
            slug
            child_menu_items {
              title
              slug
            }
          }
        }
      }
    `
  )

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(-1)
  const location = useLocation();

  function toggleMenu() {
    setIsMenuVisible(!isMenuVisible)
    // This is hacky in react but the alternative would take too long because of the hierarchy structure
    let isMobileScreenWidth = window.innerWidth < breakpoints.tablet
    if (isMobileScreenWidth && !isMenuVisible && bannerActive) {
      document.getElementById('aiLogo').setAttribute("style", "margin-top: -5.5em");
    } else if (media.phone && bannerActive) {
      document.getElementById('aiLogo').setAttribute("style", "margin-top: 0");
    }

    // disable scroll temporarily on mobile
    if (isMobileScreenWidth) {
      if(!isMenuVisible) {
        document.getElementsByTagName('html')[0].setAttribute("style", "overflow-y: hidden");
      } else {
        document.getElementsByTagName('html')[0].setAttribute("style", "overflow-y: auto");
      }
    }
  }

  function toggleSubmenuOnHover(index) {
    let isMobileScreenWidth = window.innerWidth < breakpoints.tablet
    if(!isMobileScreenWidth) {
      setIsSubmenuVisible(index)
    }
  }

  function toggleSubmenuOnClick(index) {
    let isMobileScreenWidth = window.innerWidth < breakpoints.tablet
    if(isMobileScreenWidth) {
      if(isSubmenuVisible === index) {
        setIsSubmenuVisible(-1)
      } else {
        setIsSubmenuVisible(index)
      }
    }
  }

  const order = [
    "Our work",
    "About",
    "Resources",
    "Blog",
    "Get involved",
    "Contact",
  ]
  let menu = []

  order.forEach((item) => {
    const match = allStrapiMenu.nodes.find((node) => node.title === item)
    if (match) {
      menu.push(match)
    }
  })
  menu = [...new Set([...menu, ...allStrapiMenu.nodes])]

  return (
    <Wrapper className={`${className} ${isMenuVisible ? "-menu-visible" : ""}`}>
      <Menu>
        {menu.map(({ title, slug, highlighted, child_menu_items }, index) => (
          <MenuItem
            className={`
              ${highlighted ? "-highlighted" : ""}
            `}
            key={index}
            onMouseEnter={() => {
              toggleSubmenuOnHover(index)
            }}
            onMouseLeave={() => {
              toggleSubmenuOnHover(-1)
            }}
            onClick={() => {
              toggleSubmenuOnClick(index)
            }}
          >
            <MenuLink
              to={`/${slug}`}
              className={`${location.pathname.includes('/resources') && slug === 'resources/list' ? '-active' : ''} ${child_menu_items.length > 0 ? '-parent' : ''} ${isSubmenuVisible === index ? '-expanded' : ''} `}
              partiallyActive={true}
              activeClassName="-active"
              onClick={toggleMenu}
            >
              <MenuLinkInner>{title}</MenuLinkInner>
              {child_menu_items.length > 0 &&  <Icon name="chevron-down" source="feather" />}
            </MenuLink>
            {child_menu_items.length > 0 && (
              <Submenu
                items={child_menu_items}
                type="links"
                visible={isSubmenuVisible === index}
                className={isSubmenuVisible === index ? '-active' : ''}
                onClickCallback={toggleMenu}
              />
            )}
          </MenuItem>
        ))}
        <MenuItem className="-highlighted">
          <MenuLink
            to="/donate"
            partiallyActive={true}
            activeClassName="-active"
            onClick={toggleMenu}
          >
            <MenuLinkInner>Donate</MenuLinkInner>
          </MenuLink>
        </MenuItem>
      </Menu>
      <Switcher bannerActive={bannerActive} isMenuVisible={isMenuVisible} onClick={toggleMenu}>
        <i />
      </Switcher>
    </Wrapper>
  )
}

const menuItemSidePadding = 20

const Wrapper = styled.nav`
  @media (max-width: ${breakpoints.tablet - 1}px) {
    &.-menu-visible {
      ~ a svg {
        color: #fff !important; /* todo: refactor */
      }
    }
  }
`

const Menu = styled.ul`
  ${media.tablet} {
    display: flex;
    /* margin-right: -${menuItemSidePadding}px; */
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-10px);
    /* background: rgba(0, 0, 0, 0.85); */
    /* background: ${(p) => p.theme.color.primary}; */
    background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
    text-align: center;
    color: #fff;
    transition: 0.25s;

    .-menu-visible & {
      overflow-y: auto;
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
`

const MenuItem = styled.li`
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 21px;
    position: absolute;
    bottom: -21px;
    left: 0;
  }

  ${media.tablet} {
    &.-highlighted {
      margin-left: ${menuItemSidePadding}px;
    }
  }
`

const MenuLink = styled(Link)`
  display: block;
  padding: 15px ${menuItemSidePadding}px;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  transition: 0.15s;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    font-size: 20px;

    &.-parent {
      display: inline-block;
      width: auto;
      pointer-events: none;
    }
    
    &.-expanded {
      svg {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  ${media.tablet} {
    padding-left: 7px;
    padding-right: 7px;

    &::before {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      left: 7px;
      bottom: 9px;
      background: ${(p) => p.theme.color.primary};
      transition: width 0.25s;
    }

    ${MenuItem}.-highlighted & {
      /* font-weight: 600; */
      color: #fff;

      &::before,
      &::after {
        content: "";
        width: 100%;
        height: auto;
        position: absolute;
        top: 5px;
        left: 0;
        bottom: 5px;
        border-radius: 3px;
      }

      &::before {
        background: linear-gradient(-20deg, #3ab6da, #50d9d6);
      }

      &::after {
        opacity: 0;
        background: linear-gradient(-20deg, #179cc2, #37d0cd);
        transition: opacity 0.15s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }

    /* 'li' is needed, otherwise prettier goes crazy */
    li${MenuItem}:not(.-highlighted) &.-active {
      &,
      &:hover {
        &::before {
          width: calc(100% - 7px * 2);
        }
      }
    }

    &:hover {
      &::before {
        width: 20px;
      }
    }
  }

  ${media.desktop} {
    padding-left: ${menuItemSidePadding}px;
    padding-right: ${menuItemSidePadding}px;

    &::before {
      left: ${menuItemSidePadding}px;
    }

    /* 'li' is needed, otherwise prettier goes crazy */
    li${MenuItem}:not(.-highlighted) &.-active {
      &,
      &:hover {
        &::before {
          width: calc(100% - ${menuItemSidePadding}px * 2);
        }
      }
    }
  }
`

const MenuLinkInner = styled.span`
  position: relative;
  z-index: 1;
`

const Submenu = styled(DropdownMenu)`
  min-width: 230px;
  left: ${menuItemSidePadding - 25}px;
  width: fit-content;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: block;
    opacity: 1;
    position: relative;
    left: 0;
    transform: none;
    width: 100%;
    text-align: center;
    background: transparent;
    top: 0;
    color: #fff;
    box-shadow: none;
    max-height: 0;
    transition: 0.25s;
    overflow: hidden;
    padding: 0;

    &.-active {
      max-height: 1000px;
      padding: 10px 0;
    }
  }
`

const Switcher = styled.button`
  margin-top: ${props => (props.bannerActive && !props.isMenuVisible) ? '4em' : '0'};
  width: 50px;
  height: 50px;
  padding: 18px 15px;
  position: absolute;
  top: 10px;
  right: 5px;

  &::before,
  &::after,
  i {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    position: relative;
    background: currentColor;
    transition: 0.25s;
  }

  i {
    margin: 4px 0;
  }

  .-menu-visible & {
    &::before {
      top: 6px;
      transform: rotate(45deg);
      color: ${(p) => p.theme.color.white};
    }

    &::after {
      top: -6px;
      transform: rotate(-45deg);
      color: ${(p) => p.theme.color.white};
    }

    i {
      opacity: 0;
    }
  }

  @media (max-width: ${breakpoints.phone - 5}px) {
    margin-top: ${props => (props.bannerActive && !props.isMenuVisible) ? '5.5em' : '2em'};
  }

  ${media.tablet} {
    display: none;
  }
`

const Icon = styled(BaseIcon)`
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);

  ${media.tablet} {
    display: none;
  }
`

export default HeaderNavigation
